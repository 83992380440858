<template>
  <router-view/>
</template>

<style>
.el-input__inner {
    border: 0 none;
    padding: 0;
}
</style>
