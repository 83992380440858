import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login/Login.vue'
import Layout from '../views/Layout/Layout.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/select/company',
        name: 'selectCompany',
        component: () => import(/* webpackChunkName: "selectCompany" */'../views/SelCompany/SelCompany.vue'),
        children: [
            {
                path: '/company/layout/:id',
                name: 'company',
                component: () => import(/* webpackChunkName: "company" */'../views/Company/Layout/Layout.vue')
            }
        ],
      },
      {
        path: '/system/company/list',
        name: 'systemCompanyList',
        component: () => import(/* webpackChunkName: "systemCompanyList" */ '../views/System/Company/CompanyList.vue')
      },
      {
        path: '/system/category/list',
        name: 'systemCategoryList',
        component: () => import(/* webpackChunkName: "systemCategoryList" */ '../views/System/Category/CategoryList.vue')
      },
      {
        path: '/system/manager/list',
        name: 'systemManagerList',
        component: () => import(/* webpackChunkName: "systemManagerList" */ '../views/System/Manager/ManagerList.vue')
      },
      {
        path: '/company/user/list',
        name: 'companyUserList',
        component: () => import(/* webpackChunkName: "companyUserList" */ '../views/Company/User/UserList.vue')
      },
      {
        path: '/company/enterprise/list',
        name: 'companyentErpriseList',
        component: () => import(/* webpackChunkName: "companyentErpriseList" */ '../views/Company/Enterprise/EnterpriseList.vue')
      },
      {
        path: '/company/employee/list',
        name: 'companyEmployeeList',
        component: () => import(/* webpackChunkName: "companyEmployeeList" */ '../views/Company/Employee/EmployeeList.vue')
      },
      {
        path: '/company/group/list',
        name: 'companyGroupList',
        component: () => import(/* webpackChunkName: "companyGroupList" */ '../views/Company/Group/GroupList.vue')
      },
      {
        path: '/company/item/list',
        name: 'companyItemList',
        component: () => import(/* webpackChunkName: "companyItemList" */ '../views/Company/Item/ItemList.vue')
      },
      {
        path: '/company/work/list',
        name: 'companyWorkList',
        component: () => import(/* webpackChunkName: "companyWorkList" */ '../views/Company/Work/WorkList.vue')
      },
      {
        path: '/company/work/data',
        name: 'companyWorkData',
        component: () => import(/* webpackChunkName: "companyWorkData" */ '../views/Company/Data/Data.vue')
      },
      {
        path: '/company/pay',
        name: 'companyPay',
        component: () => import(/* webpackChunkName: "companyPay" */ '../views/Company/Pay/Pay.vue')
      },
      {
        path: '/company/bill/list',
        name: 'companyBillList',
        component: () => import(/* webpackChunkName: "companyBillList" */ '../views/Company/Bill/Bill.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
