import { get,put,post } from '../utils/request'

const apiUrl = {
    userList: '/api/manage/user',
    userInfo: '/api/manage/user/:id',
    userEdit: '/api/manage/user/:id',
}


export const UserListApi = (name,phone,company_id,page,pageSize) => {
    const data = {
        name,
        phone,
        company_id,
        page,
        pageSize,
    }
    return get(
        apiUrl.userList,
        data,
    )
}

export const UserInfoApi = (id) => {
    const params = {
        id: parseInt(id)
    }

    return get(
        `/api/manage/user/${params.id}`
    )
}

export const UserAddApi = (data) =>{
    return post(
        '/api/manage/user',
        data
    )
}

export const UserEditApi = (data) => {
    const params = {
        id: parseInt(data.id)
    }
    const putData = {
        ...data.formData
    }
    return put(`/api/manage/user/${params.id}`,putData,params)
}

export const UserEditPwdApi = (data) => {
    const params = {
        id: parseInt(data.id)
    }
    const putData = {
        ...data.formData
    }
    return put(`/api/manage/user/${params.id}/pwd`,putData)
}

export const UserDeleteApi = (id) => {
    const params = {
        id: parseInt(id)
    }
    return put(`/api/manage/user/${params.id}/delete`)
}

export const UserRestoreApi = (id) => {
    const params = {
        id: parseInt(id)
    }
    return put(`/api/manage/user/${params.id}/restore`)
}