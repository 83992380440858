<template>
    <el-dialog
        v-model="visiable"
        title="密码修改"
        width="400px"
        :close-on-click-modal="false"
    >
        <el-form
            ref="formRef"
            :model="ruleForm"
            :rules="rules"
        >
            <el-form-item
                label="密码"
                prop="password"
            >
                <el-input
                    v-model="ruleForm.password"
                    type="password"
                    autocomplete="off"
                    show-password
                />
            </el-form-item>
            <el-form-item
                label="确认密码"
                prop="password_confirmation"
            >
                <el-input
                    v-model="ruleForm.password_confirmation"
                    type="password"
                    autocomplete="off"
                    show-password
                />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button
                    @click="visiable = false"
                >取消</el-button>
                <el-button
                    type="primary"
                    @click="submitForm"
                >确认修改</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { UserEditPwdApi } from '@/api/user.js'
import { checkUserCompany } from '@/utils/fn.js'

export default {
    name: 'UserPwd',
    props: {
        reload: Function // table 刷新的方法
    },
    emits:['reload'],
    setup(props, ctx) {

        const formRef = ref(null)
        const state = reactive({
            id: '',
            visiable: false,
            ruleForm: {
                password: '',
                password_confirmation:''
            },
            rules: {
                password: [
                    { required: 'true', message: '请输入新的密码', trigger: ['change','blur'] }
                ],
                password_confirmation:[
                    { required: 'true', message: '请输入新的确认密码', trigger: ['change','blur'] },
                ],
            }
        })

        const open = (id) => {
            const user = checkUserCompany()
            if(!user) {
                return
            }
            state.id = id
            state.visiable = true
        }

        const close = () => {
            state.visiable = false
        }

        const submitForm = () => {
            formRef.value.validate(valid => {
                if(valid) {

                    if(state.ruleForm.password !== '' && state.ruleForm.password_confirmation !=='' && state.ruleForm.password !== state.ruleForm.password_confirmation) {
                        ElMessage({
                            type: 'error',
                            message: '密码不一致'
                        })
                        return
                    }

                    UserEditPwdApi({
                        id: state.id,
                        formData: {
                            password: state.ruleForm.password,
                            password_confirmation: state.ruleForm.password_confirmation,
                        }
                    }).then(response => {
                        state.visiable = false
                        ElMessage({
                            type: 'success',
                            message: response.msg
                        })
                        // ctx.emit('reload')
                        if (props.reload) props.reload()
                    })
                }
            })
        }

        return  {
            ...toRefs(state),
            open,
            close,
            formRef,
            submitForm
        }
    }
}
</script>
<style scoped lang="scss">
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  width: 300px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
