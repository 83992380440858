import { get,post } from '../utils/request'

const apiUrl = {
    login: '/api/manage/login',
    captch: '/api/manage/captchas'
}

export const loginApi = (data) => {
    return post(
        apiUrl.login,
        data
    )
}

export const captchApi = () => {
    return get(
        apiUrl.captch
    )
}