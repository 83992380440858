<template>
    <div class="login-box">
        <div class="login-form">
            <el-form
                ref="formRef"
                :model="formState"
                :rules="rules"
            >
                <el-form-item
                    label="账号"
                    prop="phone"
                >
                    <el-input
                        v-model="formState.phone"
                        maxlength="11"
                        autocomplete="off"
                    />
                </el-form-item>
                <el-form-item
                    label="密码"
                    prop="password"
                >
                    <el-input
                        v-model="formState.password"
                        type="password"
                        autocomplete="off"
                        show-password
                    />
                </el-form-item>
                <el-form-item
                    label="验证码"
                    prop="captcha_code"
                >
                    <el-input
                        class="captch-input"
                        v-model="formState.captcha_code"
                        autocomplete="off"
                    />
                    <el-image
                        style="width: 150px; height:30px"
                        :src="formState.captch"
                    />
                </el-form-item>
                <el-form-item>
                    <el-input type="hidden" id="captcha_key" name="captcha_key" :value="formState.captcha_key" />
                    <el-button class="login-form-button" type="primary" @click="submitForm(formState)">登陆</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref,reactive, computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'
import { captchApi,loginApi } from '@/api/login'
import { localSet, localGet } from '@/utils/index'

export default defineComponent({
  name: 'Login',
  setup() {
    // { pattern: '/^1[3-9]\d{9}$/', message: '请输入正确手机号!' }
    const formRef = ref(null)
    const router = useRouter()
    const state = reactive({
        formState: {
            phone: '',
            password: '',
            captcha_code: '',
            captcha_key: '',
            captch: '',
            remember: true,
        },
        rules: {
            phone: [
                { required: 'true', message: '手机不能为空', trigger: ['blur'] }
            ],
            password: [
                { required: 'true', message: '密码不能为空', trigger: ['blur'] }
            ],
            captcha_code: [
                { required: 'true', message: '验证码不能为空', trigger: ['blur'] }
            ]
        }
    })

    const submitForm = () => {
            formRef.value.validate(valid => {
                if(valid) {
                    
                    loginApi(state.formState).then(res => {
                        if (res.code === 501) {
                            getCaptch()
                            state.formState.password = ''
                            state.formState.captcha_code = ''
                            ElMessage.error(res.msg)
                            return
                        }

                        if(res.code === 200) {
                            localSet('token', res.data.token)
                            localSet('user', res.data.user)
                            router.push({
                                name: 'Layout'
                            })
                            return
                        }
                    })
                    return
                }
            })
        }

    const disabled = computed(() => {
        return !(state.formState.phone && state.formState.password);
    });

    const getCaptch = () => {
        captchApi().then(res => {
            console.log(res)
            state.formState.captch = res.data.captcha_image_content
            state.formState.captcha_key = res.data.captcha_key
        })
    }

    const checkLogin = () => {
        const token = localGet('token')
        if (token) {
            router.push({
                name: 'Layout'
            })
            return
        } else {
            getCaptch()
        }
    }

    checkLogin()

    return {
        formRef,
        ...toRefs(state),
        submitForm
    }
  },

});
</script>

<style lang="scss" scoped>
.login-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    .login-form {
        border: 1px solid #dedede;
        padding: 25px;
        box-sizing: content-box;
        border-radius: 5px;
        max-width: 500px;
        margin: 0 auto;
        :deep(.ant-form-item-label){
            label{
                display: inline-block;
                width: 70px;
                text-align: left;
            }
        }
        .captch-input {
            width: calc(100% - 150px);
        }
        .login-form-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .login-form-forgot {
            margin-bottom: 24px;
        }
        .login-form-button {
            margin-top: 10px;
            width: 100%;
        }
    }
}
</style>
