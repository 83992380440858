import request from './axios.js'

/**
 * @description GET请求
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 */
 function get (url, params) {
    return request('get', url, params, null)
}

/**
 * @description POST请求
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 */
function post (url, params) {
    return request('post', url, params, null)
}

/**
 * @description PUT请求
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 */
 function put (url, data) {
    return request('put', url, data, null)
}


/**
 * @description POST请求
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 */
function form (url, params, data) {
    return request('post', url, params, data)
}

/**
 * @description 文件上传请求
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 * @param {Array<file>} files 文件上传列表
 */
function fileUpload (url, params, files) {
    let data = new FormData()
    if (files) {
        for (const file of files) {
        data.append('file', file)
        }
    }
    return request('post', url, params, data)
}

export {
    get,
    post,
    put,
    form,
    fileUpload
}