<template>
    <div class="userinfo">
        <el-popover
            placement="bottom"
            :width="200"
            trigger="click"
            popper-class="popper-user-box"
        >
            <template #reference>
                <div class="author">
                    {{ userInfo?.name }}
                </div>
            </template>
            <div class="nickname">
                <p>姓名：{{ userInfo?.name }}</p>
                <p>公司：{{ userInfo?.company }}</p>
                <p>
                    <el-button
                        type="primary"
                        text
                        @click="handleChangePwd(userInfo.id)"
                    >修改密码
                    </el-button>
                </p>
                <el-button class="logoutBtn" type="danger" size="small" @click="logout">退出</el-button>
            </div>
        </el-popover>
        <UserPwd
            ref="editUserPwd"
        />
    </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ArrowDown } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
import { localGet,localRemove } from '@/utils'
import UserPwd from '@/views/Company/User/UserPwd.vue'

export default {
    name: 'UserInfoVue',
    components: {
        ArrowDown,
        UserPwd
    },
    setup() {
        const router = useRouter()
        const editUserPwd = ref(null)
        const state = reactive({
            type: 'edit'
        })
        let userInfo = reactive({
            id: '',
            name: '',
            company: ''
        })

        const logout = () => {
            ElMessageBox.confirm(
                '确定要退出当前账号么？',
                'Warning',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }
            ).then(()=>{
                // console.log('确定了')
                localRemove('token')
                localRemove('user')
                router.push("/login")
            }).catch(() => {
                console.log('取消了')
            })
        }

        const handleChangePwd = (id) => {
            console.log(id)
            editUserPwd.value.open(id)
        }

        userInfo = localGet('user')

        return {
            state,
            editUserPwd,
            userInfo,
            logout,
            handleChangePwd
        }
    }
}

</script>

<style lang="scss" scoped>
.userinfo {
    .author {
        cursor: pointer;
    }
}
.nickname {
    .logoutBtn {
        width: 100%;
    }
}
</style>
