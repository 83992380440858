import { localGet } from "./index.js"
import { ElMessage } from 'element-plus'

export const checkUserCompany = () => {
    const user = localGet('user')

    if (!user.company_id) {
        ElMessage({
            type: 'error',
            message: '公司信息异常，无法获取数据'
        })
        return false
    }

    return user
}

export const diffWorkTime = (startTime, endTime, resetTime) => {
    const _startTime = takeMinute(startTime)
    const _endTime = takeMinute(endTime)
    
    const _workTime = (_endTime - _startTime - parseInt(resetTime)) / 60

    return  _workTime.toFixed(2)
}

const takeMinute = (time) => {
    const _time = time.split(":")
    const _minute = parseInt(_time[0]) * 60 + parseInt(_time[1])

    return _minute
}


export const afterWeek = (nowDate) => {
    const _date = nowDate.split('-')
    let _year = parseInt(_date[0])
    let _month = parseInt(_date[1])
    let _day = parseInt(_date[2])

    const _monthDays = new Date(_year, (_month + 1), 0).getDate()

    if(_day + 7 <= _monthDays) {
        _day = _day + 7
    } else {
        if((_month + 1) < 12) {
            _month++
            _day = 7 - (_monthDays - _day)
        } else {
            _month = 0
            _year++
            _day = 7 - (_monthDays - _day)
        }
    }

    return  `${_year}-${(Array(2).join(0)+_month).slice(-2)}-${(Array(2).join(0)+_day).slice(-2)}`
}