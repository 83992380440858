import router from '@/router/index.js'
import axios from 'axios'
import config from '../config/config.js'
import {localGet, localRemove} from './index'
import { ElMessage } from 'element-plus'

const baseURL = config[process.env.NODE_ENV].baseURL

const myAxios = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Access-Control-Allow-Mehtods': 'GET,POST,PATCH,PUT,OPTIONS',
        'Content-Type': 'application/json,charset=utf-8'
    },
    baseURL: baseURL,
    timeout: 2 * 60 * 1000,
    withCredentials: true
})

const downLoadFilePath =[
    '/api/manage/employee/excel/download',
    '/api/manage/work/excel/download',
    '/api/manage/data/excel/download'
]

myAxios.interceptors.request.use(
    config => {
        // 特殊配置：设置下载获取 excel 接口的返回值为 blob。这在异步下载文件时有时会显得很有用。
        if (inArray(config.url, downLoadFilePath)) {
            config.responseType = 'blob'
            // config.headers['Content-Type'] = 'application/x-download'
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }

        checkLoginState(config)

        return setHeaderToken(config)
    },
    error => {
        console.warn(error)
        return Promise.reject(error)
    }
)


myAxios.interceptors.response.use(
    response => {
        console.log(response)
        const res = response.data
        // 特殊配置：code为 -10086，表示资源不存在，跳转到提示页（404）
        if (res.code === 404) {
                router.push({
                name: 'ErrorPage',
                query: { type: '404' }
            }).then()

            return res
        }
    
        // 特殊配置：-12306，无权限，跳转到提示页
        if (res.code === -12306) {
            router.push({
                name: 'ErrorPage',
                query: { type: 'authority' }
            }).then()

            return res
        }

        // 特殊配置：异步下载文件中有用，一般用不上，导出 word、excel 文件接口
        // 注意此时返回的是完整的 response，开放更多信息便于处理下载过程
        const url = response.config.url
        // if (inArray(config.url, downLoadFilePath)) {
        if (url === '/api/word/download' || url === '/api/excel/download') {
            response.headers.responseType = 'blob'
            return response
        }

        console.log(url)
        if (inArray(url, downLoadFilePath)) {
            return res
        }

        

        // if (typeof res.data !== 'object' || typeof res.data !== 'boolean') {
        //     ElMessage.error('服务端异常！');
        //     return Promise.reject(res)
        // }

        if (res.code == 419 || res.code == 410) {
            router.push({
                path: '/login'
            })
            return Promise.reject(res.data)
        }

        if ( response.code === 500) {
            ElMessage.error(res.message);
            return
        }

        if(res.code != 200) {
            ElMessage.error(res.msg)            
        }

        return res
    },
    error => {
        if (error.message === 'Request failed with status code 401' ) {
            localRemove('token')
            router.push({
                path: '/login'
            })
        }
        if (error.message === 'Request failed with status code 401' ) {
            localRemove('token')
            router.push({
                path: '/login'
            })
        }
        if (error.response?.status === 422) {
            ElMessage.error(error.message)
            return
        }

        ElMessage.error(error.message)
        return Promise.reject(error)
    }
)

/**
 * @description 请求工具方法，不开放使用
 * @param {string} method 请求方法
 * @param {string} url 请求相对路径
 * @param {Object} params 请求参数
 * @param {FormData} data 数据体
 */
 function request (method, url, params, data) {
    console.log('查看请求参数', params)
    let options = {
            method,
            url,
        // headers: {
        //     Accept: 'application/vnd.fyplatform.v1+json'
        // }
    }

    if (method === 'get') {
        options.params = params
    } else {
        options.data = params
    }
    if (params) {
        options.data = params
    }
    if (data) {
        options.headers['Content-Type'] = 'multipart/form-data'
        options.data = data
    }
    return myAxios(options)
}

const setHeaderToken = (config) => {
    const token = localGet('token')
    token && (config.headers['Authorization'] = `Bearer ${token}`)

    return config
}

export const inArray = (search, array) => {
    for (var i in array) {
      if (array[i] == search) return true
    }
    return false
}


const noAuthPath =[
    '/api/manage/login'
]

const checkLoginState = (config) => {
    if (config?.url && inArray(config.url, noAuthPath)){

    } else {
        const token = localGet('token')
        if (!token) {
            localRemove('token')
            router.push({
                path: '/login'
            })
        }
    }
}


export default request