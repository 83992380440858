import { useRouter } from 'vue-router'

export function localGet(key) {
    const value = window.localStorage.getItem(key)
    try {
        return JSON.parse(window.localStorage.getItem(key))
    } catch(error) {
        return value
    }
}

export function localSet(key,value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove(key) {
    window.localStorage.removeItem(key)
}

export const getToken = () => {
    return localGet('token')
}

export function userRole(){
    const _user = localGet('user')

    if(!_user) {
        const router = useRouter()

        localRemove('token')
        localRemove('user')
        router.push({
            path: '/login'
        })
        return 
    }

    if(_user.role == undefined) {
        console.log('123123')
    }

    if((_user && _user.role == '') || _user.role == undefined){
        const router = useRouter()

        localRemove('token')
        localRemove('user')
        router.push({
            path: '/login'
        })
        return 
    }

    return _user
}
