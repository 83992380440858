<template>
    <el-menu
        class="el-menu-vertical-demo"
        text-color="#fff"
        @open="handleOpen"
        @close="handleClose"
        :router="false"
      >
        <el-menu-item-group
            title="临时工管理"
            v-if="user.role >=1 && user.company_id !=null"
        >
          <template
            v-if="user.role >= 1 && user.company_id !=null && user.role <= 4"
          >
            <el-menu-item>
              <router-link to="/company/work/list">工作记录</router-link>
            </el-menu-item>
          </template>

          <template
            v-if="user.role >= 1 && user.company_id !=null && user.role <= 4 && user.role != 4"
          >
            <el-menu-item>
              <router-link to="/company/employee/list">临时工管理</router-link>
            </el-menu-item>
            <el-menu-item>
                <router-link to="/company/pay">工资发放</router-link>
            </el-menu-item>
          </template>

          <template
            v-if="user.role >= 1 && user.company_id !=null && user.role <= 4"
          >
            <el-menu-item>
              <router-link to="/company/work/data">数据透析</router-link>
            </el-menu-item>
          </template>

          <template
            v-if="user.role >= 1 && user.company_id !=null && user.role <= 2"
          >
            <el-menu-item>
                <router-link to="/company/user/list">公司员工</router-link>
            </el-menu-item>
            <el-menu-item>
                <router-link to="/company/enterprise/list">商家管理</router-link>
            </el-menu-item>
            <el-menu-item>
                <router-link to="/company/group/list">分组管理</router-link>
            </el-menu-item>
          </template>

          <template
            v-if="user.role >= 1 && user.company_id !=null && user.role <= 4 && user.role != 4"
          >
            <el-menu-item>
                <router-link to="/company/item/list">项目管理</router-link>
            </el-menu-item>
          </template>

            <!-- <el-menu-item>
                <router-link to="/company/bill/list">发放记录</router-link>
            </el-menu-item> -->
        </el-menu-item-group>

        <el-menu-item-group title="系统配置" v-if="user.role == 1  && user.company_id == ''">
            <el-menu-item>
                <router-link to="/system/company/list">公司管理</router-link>
            </el-menu-item>
            <el-menu-item>
                <router-link to="/system/manager/list">公司管理员</router-link>
            </el-menu-item>
        </el-menu-item-group>
    </el-menu>
</template>

<script>
import { defineComponent, onMounted,reactive } from 'vue'
import { userRole } from '@/utils/index'

export default defineComponent({
  name: 'MenuLk',
  setup() {
    const user = reactive({
        role: null,
        company_id: null
    })
    const handleOpen = () => {
      return
    }
    const handleClose = () => {
      return
    }

    onMounted(() => {
        const _user = userRole()
        user.role = _user.role
        user.company_id = _user.company_id

        console.log(user)
    })

    return {
        user,
        handleOpen,
        handleClose
    }
  }
});
</script>

<style lang="scss" scoped>
.el-menu {
    border-right: none!important;
    background-color: #fff;
}
.el-menu-item {
    color: #333;
}
.el-submenu {
  border-top: 1px solid hsla(0, 0%, 100%, .05);
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}
.el-submenu:first-child {
  border-top: none;
}
.el-submenu [class^="el-icon-"] {
  vertical-align: -1px!important;
}
a {
  color: #409eff;
  display: block;
  text-decoration: none;
  width: 100%;
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}
.el-popper__arrow {
  display: none;
}
</style>
