export default {
    development : {
        baseURL: 'http://127.0.0.1:8092'
    },
    beta: {
        baseURL: '//api.lklsg.92show.cc' // 测试接口域名
    },
    release: {
        baseURL: '//api.lklsg.92show.cc' // 正式接口域名，有些打包出来是这个名字
    },
    production : {
        baseURL: '//api.lklsg.92show.cc' // 正式接口域名，有些打包出来是这个名字
    }
}