<template>
    <div class="layout">
        <el-container class="container">
            <el-aside width="200px">
                <div class="head">
                    <div>
                        <span>临空-Admin</span>
                    </div>
                </div>
                <Menu  />
            </el-aside>

            <el-container class="mian-area">
                <el-header>
                    <div class="left"></div>
                    <div class="right">
                        <User-info/>
                    </div>
                </el-header>

                <el-main
                :style="{ padding: '24px', background: '#fff', minHeight: '360px' }"
                >
                <router-view></router-view>
                </el-main>
                
                <el-footer>Footer</el-footer>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue';
import { useRouter } from 'vue-router'
import Menu from '../../components/Menu.vue'
import UserInfo from '@/components/UserInfo.vue';
import { localGet,localRemove } from '../../utils/index'

export default defineComponent({
  name: 'Layout',
  components: {
    Menu,
    UserInfo
},
  data() {
    return {
      collapsed: ref(false),
      selectedKeys: ref(['1']),
    };
  },
  setup(){
    const router = useRouter()

    const loginOut = () => {
      Modal.confirm({
        title: '',
        icon: createVNode(ExclamationCircleOutlined),
        content: '您确定要退出么?',

        onOk() {
            localRemove('token')
            router.push("/login")
            // return new Promise((resolve, reject) => {
            //     setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
            console.log('点击了取消')
        },

      });
    };

    const checkLogin = () => {
        const token = localGet('token')
        console.log(token)
        if (!token) {
            router.push({
                name: 'login'
            })
            return
        }
    }

    checkLogin()

    return {
        loginOut
    }
  }
});
</script>
<style lang="scss" scoped>
.layout {
    min-height: 100vh;
    background-color: #fff;
    .el-aside {
        width: 200px;
        background-color: #fff;
        border-right: 1px solid #dedede;
        .head {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            border-bottom: 1px solid #dedede;
            box-sizing: border-box;
            background-color: #409EFF;
            div {
                display: flex;
                align-items: center;
                span {
                    font-size: 20px;
                    color: #fff;
                }
            }
        }
    }
    .container {
        height: 100vh;
        .mian-area {
            .el-header {
                display: flex;
                justify-content: space-between;
                height: 50px;
                align-items: center;
                border-bottom: 1px solid #dedede;
                box-sizing: border-box;
                background-color: #409EFF;
                color: #fff;
            }
        }
    }
}
</style>
